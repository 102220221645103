import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SecuredMarketingConsents } from '@resursbank/ya-react-lib';

const stateToProps = state => {
    return {
        env: state.info.env,
    };
};

const MarketingConsent = ({ env }) => {
    const data = useMemo(() => {
        const isProd = env === 'prod';
        return {
            endpointUrl: isProd
                ? 'https://consentmanager-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
                : 'https://consentmanager-cust-yabank-stage.apps.okd.paas.h.bitbit.net',
            keycloakData: {
                realm: 'ansatte',
                clientId: 'forside',
                url: isProd
                    ? 'https://keycloak.yabank.c.bitbit.net'
                    : 'https://keycloak-test.yabank.c.bitbit.net',
            },
            cmsHelperUrl: isProd
                ? 'https://cmshelper-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
                : 'https://cmshelper-cust-yabank-stage.apps.okd.paas.h.bitbit.net',
        };
    }, [env]);

    return <SecuredMarketingConsents {...data} />;
};

MarketingConsent.propTypes = {
    env: PropTypes.string,
};

export default connect(stateToProps)(MarketingConsent);
